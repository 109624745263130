import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"

import { useNavigate } from 'react-router-dom';

import "../stylesheetCss.css"

import specialScenarioImage from "../../assets/specialScenarioImage.png"
import contactUsMask from "../../assets/vector.png"
import { productsParaPart1, spCard1, spCard2, spCard3, spCardP1, spCardP2, spCardP3 } from '../../urls/contactusKeys';
import { useTranslation } from 'react-i18next';

function SpecialScenario() {

    const { t, i18n } = useTranslation();

    return (
        <>

            <Grid container spacing={2} style={{
                // background Image cover 
                backgroundImage: `url(${contactUsMask})`,
                backgroundColor: "transparent", marginBlock: '3%', marginBottom: '6%'
            }}>
                <Grid item xs={12} md={12} align="center">
                    <Typography className='poppinsSemiRegularBold'
                        variant='h6'
                        sx={{
                            fontSize: {
                                xs: "30px",
                                sm: "30px",
                                md: "44px",
                            },
                            fontWeight: 500,
                            letterSpacing: '2%',
                            color: '#0C0C0C',
                            // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                            width: '100%',
                            marginBottom: '2%',
                            textTransform: 'capitalize',
                        }}>
                        {t(productsParaPart1)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={1} >
                </Grid>
                <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar
                        className='avatar-large-screen'
                        src={specialScenarioImage} alt="top"
                        variant='square'
                        style={{
                            width: '100%',
                            height: 'auto',

                        }} />




                </Grid>
                <Grid className='avatar-large-screen ' item xs={12} md={7} style={{ position: 'relative' }}>
                    <Grid container spacing={2} style={{ backgroundColor: "transparent", marginBottom: '6%' }}>
                        <Grid item xs={12} md={12} align="left">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white', width: '53%' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t(spCard1)}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t(spCardP1)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} align="center">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white', width: '53%', textAlign: 'left' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t(spCard2)}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t(spCardP2)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} align="left">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white', width: '53%' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t(spCard3)}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t(spCardP3)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} align="center">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white', width: '53%', textAlign: 'left' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {/* {t(spCard2)} */} {t("Create You're Account")}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {/* {t(spCardP2)} */}
                                        {t("To play it’s simple just create your account and stay tuned to our lives on TikTok, play and chose your ball when the host open the game and watch the race live, good luck")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>


                </Grid>
                <Grid className='avatar-small-screen ' item xs={12} md={7} style={{ position: 'relative' }}>
                    <Grid container spacing={2} style={{ backgroundColor: "transparent", marginBottom: '6%' }}>
                        <Grid item xs={12} md={12} align="left">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t(spCard1)}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t(spCardP1)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} align="center">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white', textAlign: 'left' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t(spCard2)}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t(spCardP2)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} align="left">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t(spCard3)}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t(spCardP3)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} align="left">
                            <Card style={{ boxShadow: '-4px 4px 25px -5px rgba(0,0,0,0.47)', borderRadius: '22px', backgroundColor: 'white' }}>
                                <CardContent>
                                    <Typography variant='h6' className='poppinsSemiRegularBold' style={{ color: '#F5BC01', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '20px' }}>
                                        {t("Create You're Account")}
                                    </Typography>
                                    <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                        {t("To play it’s simple just create your account and stay tuned to our lives on TikTok, play and chose your ball when the host open the game and watch the race live, good luck")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>


                </Grid>




            </Grid>


        </>
    );
}

export default SpecialScenario;