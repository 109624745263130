import { Avatar, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import circle from "../../assets/web1.png"
import './stylesheet.css'
import { get } from '../../urls/api';
import { useState } from 'react';
import activeUsers from "../../assets/activeUsersCardHero.png"
import totalDownloadCardHero from "../../assets/totalDownloadCardHero.png"
import gamePlayedCardHero from "../../assets/gamePlayedCardHero.png"
import { heroParagraph, heroheaderPart1 } from '../../urls/contactusKeys';
import abbreviateNumber from '../../utils/abbreviateNumber';

import { useTranslation } from 'react-i18next';

function HeroSection() {

    const { t, i18n } = useTranslation();
    const [savedlanguage, setSavedlanguage] = useState("");

    const [activeUsersCount, setActiveUsersCount] = useState(0)
    const [deletedUsers, setDeletedUsers] = useState(0)
    const [gamesPlayed, setGamesPlayed] = useState(0)

    const getLatestNews = async () => {
        console.log("get all latest news")

        const apiData = await get('feedback/get_dashboard_counts'); // Specify the endpoint you want to call
        console.log("Active Blogs Details")
        console.log(apiData)
        if (apiData.error) {
        } else {
            setActiveUsersCount(apiData?.total_active_users)
            setDeletedUsers(apiData?.total_downloads)
            setGamesPlayed(apiData?.total_games)
        }
    }
    React.useEffect(() => {
        const savedLang = localStorage.getItem('lang');
        console.log("savedLang", savedLang)
        setSavedlanguage(savedLang);

        getLatestNews()
    }, [])

    return (
        <>

            <Grid container spacing={2}

                id="smallScreenHero"
                style={{
                    position: 'relative',
                    // backgroundColor:'#734836',
                    // background: 'rgb(255,255,255)',
                    // background:' linear-gradient(73deg, rgba(255,255,255,1) 16%, rgba(3,53,107,1) 100%)',
                    background: 'linear-gradient(126deg, rgba(255,255,255,1) 34%, rgba(189,243,254,0.3029586834733894) 100%)',
                    display: 'flex',
                    alignItems: 'center',
                    // marginTop: 80


                }}>





                <Grid item xs={12} md={12} mt={12} id="smallScreenImageLarge" >

                    <div style={{
                        position: 'relative',

                    }}>
                        <div style={{
                            backgroundImage: `url(${circle})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '100vh',
                            display: { xs: 'none', md: 'block' }
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                </Grid>
                                <Grid item xs={12} md={8} align="center" sx={{ marginBlock: "5%" }}>
                                    <Typography className='poppinsSemiRegularBold'
                                        variant='h6'
                                        sx={{
                                            textAlign: 'center',
                                            color: '#F5BC01',
                                            lineHeight: '80px',
                                            letterSpacing: '1px',
                                            marginBottom: '10px',
                                            width: '100%',
                                            textTransform: 'capitalize',
                                            fontSize: "66px",

                                        }}>
                                        {t(heroheaderPart1)}
                                    </Typography>
                                    <Typography className='poppinsLight'
                                        variant='h6'
                                        sx={{
                                            textAlign: 'center',
                                            color: '#FFFFFF',
                                            marginBottom: '10px',
                                            width: '80%',
                                            fontSize: '20px',

                                        }}>

                                        {t(heroParagraph)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} md={3}>
                                            {/* <Avatar src={activeUsers} alt="active "/> */}
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <Avatar src={activeUsers} alt="active " style={{ width: '65px', height: 'auto' }} />

                                                </Grid>
                                                <Grid item xs={12} md={8} >
                                                    <Typography variant='h4'
                                                        style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: 500 }}
                                                        className='poppinsSemiRegularBold'>
                                                        {abbreviateNumber(activeUsersCount)}
                                                    </Typography>
                                                    <Typography variant='h4'
                                                        style={{ color: '#FFFFFF', fontSize: '22px', fontWeight: 500 }}

                                                        className='poppinsSemiRegularBold'
                                                    >
                                                        {t("Active Users")}
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* second card  */}
                                        <Grid item xs={12} md={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <Avatar src={totalDownloadCardHero} alt="active " style={{ width: '65px', height: 'auto' }} />

                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant='h4'
                                                        style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: 500 }}
                                                        className='poppinsSemiRegularBold'>

                                                        {abbreviateNumber(deletedUsers)}
                                                    </Typography>
                                                    <Typography variant='h4'
                                                        style={{ color: '#FFFFFF', fontSize: '22px', fontWeight: 500 }}

                                                        className='poppinsSemiRegularBold'
                                                    >
                                                        {t("Downloads")}
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* third  */}
                                        <Grid item xs={12} md={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <Avatar src={gamePlayedCardHero} alt="active " style={{ width: '65px', height: 'auto' }} />

                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant='h4'
                                                        style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: 500 }}
                                                        className='poppinsSemiRegularBold'>
                                                        {abbreviateNumber(gamesPlayed)}
                                                    </Typography>
                                                    <Typography variant='h4'
                                                        style={{ color: '#FFFFFF', fontSize: '22px', fontWeight: 500 }}

                                                        className='poppinsSemiRegularBold'
                                                    >
                                                        {t("Games Played")}
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={3}>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </div>

                    </div>



                </Grid>
                <Grid item xs={12} mt={12} style={{ backgroundColor: 'white' }}
                    md={12} id="smallScreenImage">
                    <Grid container spacing={2} style={{
                        backgroundImage: `url(${circle})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',

                        height: '100vh'
                    }}>
                        <Grid item xs={1} md={1}></Grid>
                        <Grid item xs={10} md={10} mt={20} style={{ position: 'relative' }}>

                            <Typography className='poppinsSemiRegularBold'
                                variant='h6'
                                sx={{
                                    position: 'absolute',

                                    top: -120, left: 0, bottom: 10,
                                    textAlign: 'center',
                                    color: '#F5BC01',
                                    width: '100%',
                                    textTransform: 'capitalize',
                                    fontSize: '20px',

                                }}>
                                {t(heroheaderPart1)}
                            </Typography>
                            <Typography className='poppinsLight'
                                variant='h6'
                                sx={{
                                    position: 'absolute',
                                    textAlign: "center",

                                    top: 0, left: 0,
                                    color: 'white',
                                    // width: '100%',
                                    textTransform: 'capitalize',
                                    fontSize: '20px',

                                }}>
                                {t(heroParagraph)}

                            </Typography>

                        </Grid>
                        <Grid item xs={1} md={1}>

                        </Grid>
                        {/* cards  */}


                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} mt={3} align="center">
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <Avatar src={activeUsers} alt="active " style={{ width: '65px', height: 'auto' }} />

                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <Typography variant='h4'
                                                style={{ color: 'gray', fontSize: '22px', fontWeight: 500 }}
                                                className='poppinsSemiRegularBold'>
                                                {activeUsersCount}
                                            </Typography>
                                            <Typography variant='h4'
                                                style={{ color: 'gray', fontSize: '16px', fontWeight: 500 }}

                                                className='poppinsSemiRegularBold'
                                            >
                                                {t("Active Users")}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* second card  */}
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <Avatar src={totalDownloadCardHero} alt="active " style={{ width: '65px', height: 'auto' }} />

                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <Typography variant='h4'
                                                style={{ color: 'gray', fontSize: '22px', fontWeight: 500 }}
                                                className='poppinsSemiRegularBold'>
                                                {deletedUsers}
                                            </Typography>
                                            <Typography variant='h4'
                                                style={{ color: 'gray', fontSize: '16px', fontWeight: 500 }}

                                                className='poppinsSemiRegularBold'
                                            >
                                                {t("Downloads")}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* third  */}
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Avatar src={gamePlayedCardHero} alt="active " style={{ width: '65px', height: 'auto' }} />

                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant='h4'
                                                style={{ color: 'gray', fontSize: '22px', fontWeight: 500 }}
                                                className='poppinsSemiRegularBold'>
                                                {gamesPlayed}
                                            </Typography>
                                            <Typography variant='h4'
                                                style={{ color: 'gray', fontSize: '16px', fontWeight: 500 }}

                                                className='poppinsSemiRegularBold'
                                            >
                                                {t("Games Played")}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
}

export default HeroSection;