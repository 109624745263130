// Footer 
export const emailKey = `cueball_support@gmail.com`;
export const phoneNoKey = `+1-000-000-0000`;
export const phoneNoKey1 = `033436173026`;

export const locationKey = `123 Main Street, City ville, USA`;
export const FooterContent = `Leverage our state-of-the-art technology, tailored to meet the unique demands of your evolving business landscape.`;
// hero section home
export const heroheaderPart1 = `Your Gateway to Exciting Live Prediction Gaming!`;
export const heroheaderPart2 = `With Us`;
export const heroParagraph = `Cue Ball opens the gateway to the thrilling world of live prediction gaming. Dive in, make your predictions, witness real-time pool matches, and savor the sweet taste of victory with exciting prizes. It's that simple – experience the pulse of the game!`;
// About us
export const aboutUsHead = `About Cue-Ball`;
export const aboutUsHeader = `Step into the captivating world of Cue Ball, where it transcends beyond a mere game—it's an immersive experience! Brace yourself for the exhilarating realm of live prediction gaming, where you become the oracle, foreseeing the winning ball in real-time pool matches unfolding at various locations.`;
export const aboutUsPara = `Our interactive platform is more than a gaming interface; it's a portal to the heart of live events. Feel the pulse of the game as you make predictions, engage with real-time pool action, and elevate your gaming journey to unprecedented heights. At Cue Ball, we redefine gaming, turning it into a dynamic, interactive adventure that brings the thrill of live events right to your fingertips.`;

export const productsHead2 = `Why Cue-Ball?`;
export const productsHead3 = `Dive into a world of innovation and seamless functionality with our powerhouse features.`;

export const cardHead1 = `Live Gaming Experience`;
export const cardPara1 = `Experience the excitement of live prediction gaming with real pool games played at various locations.`;
export const cardHead2 = `Fair and Transparent`;
export const cardPara2 = `Our platform ensures fairness in gameplay and transparent distribution of winnings.`
export const cardHead3 = `Secure Transactions`;
export const cardPara3 = `Your payments and withdrawals are securely processed through trusted payment gateways like PayPal.`;
export const cardHead4 = `User-Friendly Interface`;
export const cardPara4 = `Enjoy a seamless and intuitive user interface, making your gaming experience enjoyable and hassle-free.`;


export const productsParaPart1 = `Special Scenarios`;
// Special Scenario 
export const spCard1 = `White Ball Wins`
export const spCardP1 = `In this scenario, all entry fees go into the admin's account.`
export const spCard2 = `No Winner`
export const spCardP2 = `If no player selects the winning ball, the game restarts without entry fees.`
export const spCard3 = `8th or 9th Ball Wins`;
export const spCardP3 = `If the 8th ball wins, users who selected balls 1 to 7 are winners. If the 9th ball wins, users who selected balls 9 to 15 are winners.`
// Mobile section 
export const mobile1 = `Check recent victories. See players who predicted the winning ball take their place in the Winners' Circle. Will your name be next? Stay tuned and revel in the triumphs!🎱🌟`
export const mobile2 = `History screen provides a timeline of your gaming journey, showcasing the games you've participated in.📜  Wallet screen is your financial hub, where every winning finds its place. 🏆💸`

// Contact
export const Featureheader = `Contact us`;
export const FeaturePara = `Got questions, suggestions, or just want to chat? We're here for you. We value your feedback and can't wait to hear from you!`;
export const modalHeader = "Immerse yourself in live pool games happening at various locations."
export const sendPaymentText = `Get Started Today!`
export const modalHeader1 = `Join Cue Ball now and embark on a journey of thrilling live prediction games. Sign up, make your predictions, and compete with others for exciting prizes!`
// payment success keys 
export const paymentHeader = `Your Payment is Successful`
export const thnksNote = `  Thank you for your payment .An automated information for user login is sent to your email`

// ----------------------------------------------Contact Us  ---------------------
export const footerNote = "Shape your legacy in our 8-ball universe, where victories roll in spheres!"
export const contactUsHeaderHero = `Get in Touch`;
export const contactUsParaHero = ` Have a question or need help with our products? Our team is always here to assist you. Contact us today and let us help you unlock the full potential of digital maps for your business.`;

export const contactusCardHead = `24/7 Support`;
export const contactusCardPara = `Have a question or need help with our products? Our team is always here to assist you. Contact us today and let us help you unlock the full potential of digital maps for your business.`;

export const contactUsMessageAlert = `Message Sent . We will contact you soon !`;
export const contactUsMessageAlertError = `Something went Wrong`;
