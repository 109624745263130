import { Avatar, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../stylesheetCss.css"
import groupBallsLeftAbout from "../../assets/groupBallsLeftAbout.png"
import groupBallsRightAbout from "../../assets/groupBallsRightAbout.png"
import imagesAbout from "../../assets/imagesAbout.png"
import { aboutUsHead, aboutUsHeader, aboutUsPara } from '../../urls/contactusKeys';
import { useTranslation } from 'react-i18next';


function ProductsData() {

    const { t, i18n } = useTranslation();

    return (
        <>

            <Grid container spacing={2} pt={10} style={{ position: 'relative', backgroundColor: "transparent", marginBlock: '2%', marginBottom: '6%', marginInline: '1%' }}>
                <span className='avatar-large-screen '
                    style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Avatar src={groupBallsRightAbout} alt="top"
                        variant='square'
                        style={{
                            width: '208px',
                            height: 'auto',

                        }} /></span>
                <span className='avatar-large-screen ' style={{ position: 'absolute', bottom: '5%', left: 0 }}>
                    <Avatar src={groupBallsLeftAbout} alt="top"
                        variant='square'
                        style={{
                            width: '208px',
                            height: 'auto',

                        }} /></span>
                <Grid item xs={12} md={1} >
                </Grid>
                <Grid item xs={12} md={4} style={{ position: 'relative' }}>
                    <Avatar
                        src={imagesAbout} alt="top"
                        variant='square'
                        className='avatar-large-screen '
                        style={{
                            width: '100%',
                            height: 'auto',

                        }} />




                </Grid>
                {/* <Grid item xs={1} ></Grid> */}
                <Grid item xs={12} md={6} style={{ position: 'relative' }}>

                    <Typography className='poppinsSemiRegularBold '
                        variant='h6'
                        sx={{
                            // font size differnt for small screen
                            fontSize: {
                                xs: "30px",
                                sm: "30px",
                                md: "44px",
                            },

                            fontWeight: 500,
                            letterSpacing: '2%',
                            color: '#0C0C0C',
                            // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 3px #F5BC01',
                            marginBlock: '6%', marginBottom: '1%',
                            width: '100%',
                            textTransform: 'capitalize',
                        }}>
                        {t(aboutUsHead)}
                    </Typography>
                    <Typography variant='h6' className='lexendLight' sx={{
                        color: '#888888', fontWeight: 700,
                        maxWidth: {
                            xs: '80%',
                            sm: '80%',
                            md: '90%'
                        },

                    }}>
                        {t(aboutUsHeader)}
                    </Typography>
                    <Typography variant='h6' className='lexendLight' sx={{
                        color: '#888888', fontWeight: 700,
                        maxWidth: {
                            xs: '80%',
                            sm: '80%',
                            md: '90%'
                        }, marginTop: '2%'
                    }}>

                        {t(aboutUsPara)}
                    </Typography>



                </Grid>
                <Grid item xs={12} md={1} >
                </Grid>



            </Grid>


        </>
    );
}

export default ProductsData;