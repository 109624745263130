// import logo from './logo.svg';
import * as React from "react";
import "../../App.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import logo from "../../assets/logom.png";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { get } from "../../urls/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import flag_eng from "../../assets/flag_eng.png";
import flag_spanish from "../../assets/flag_spanish.png";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu as MenuIcon,
} from "@mui/icons-material";

function AppbarHomw() {
  const theme = useTheme();
  const [download_now_url, setDownload_now_url] = React.useState("");

  function handleScroll(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  }
  const getSocialLinks = async () => {
    //call api  privacy_policy/getSocialLinks to get all in console
    const apiData = await get("privacy_policy/getDownloadButtons"); // Specify the endpoint you want to call
    console.log("Active Blogs Details");
    console.log(apiData);
    if (apiData.error) {
    } else {
      console.log("button urls");
      setDownload_now_url(apiData.data.download_now_url);
    }
  };
  React.useEffect(() => {
    getSocialLinks();
  }, []);

  // change language

  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    handleClose(); // Close menu after selection
  };

  const getCurrentFlag = () => {
    if (i18n.language === "en") {
      return {
        src: flag_eng,
        label: "English",
      };
    }
    if (i18n.language === "es") {
      return {
        src: flag_spanish,
        label: "Spanish",
      };
    }
    return {
      src: flag_eng,
      label: "English",
    }; // Default to English if language is not recognized
  };

  const currentFlag = getCurrentFlag();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => handleScroll("home")}>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {t("Home")}
          </p>
        </ListItem>
        <ListItem button onClick={() => handleScroll("about")}>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {t("About Us")}
          </p>
        </ListItem>
        <ListItem button onClick={() => handleScroll("features")}>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {t("Features")}
          </p>
        </ListItem>
        <ListItem button onClick={() => handleScroll("testimonials")}>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {t("Special Scenarios")}
          </p>
        </ListItem>
        <ListItem button onClick={() => handleScroll("contact_us")}>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {t("Contact us")}
          </p>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      {/* Appbar  */}
      <AppBar
        position="fixed"
        style={{
          height: "100px",
          paddingTop: "10px",
          top: 0,
          backgroundColor: "#060502",
          // boxShadow: 'none',
          boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
          borderBottom: "0.5px solid rgba(198,198,198,1);",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container>
              <Grid item xs={3} md={2}>
                <Box sx={{ flexGrow: 1 }}>
                  <Avatar
                    sx={{ width: { xs: "80%", md: "80px" }, height: "auto" }}
                    alt="Logo"
                    src={logo}
                    variant="square"
                  />
                </Box>
              </Grid>
              <Grid item xs={9} md={10}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{
                    pt: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "right",
                  }}
                >
                  {/* List items Home About Contact us in a flex  */}
                  <Button
                    key="home"
                    sx={{ fontSize: "12px", color: "#fff", fontWeight: "bold" }}
                    onClick={() => handleScroll("home")}
                  >
                    {t("Home")}
                  </Button>
                  <Button
                    key="about"
                    onClick={() => handleScroll("about")}
                    sx={{ fontSize: "12px", color: "#fff", fontWeight: "bold" }}
                  >
                    {t("About Us")}
                  </Button>
                  <Button
                    key="features"
                    onClick={() => handleScroll("features")}
                    sx={{ fontSize: "12px", color: "#fff", fontWeight: "bold" }}
                  >
                    {t("Features")}
                  </Button>
                  <Button
                    key="testimonials"
                    onClick={() => handleScroll("testimonials")}
                    sx={{ fontSize: "12px", color: "#fff", fontWeight: "bold" }}
                  >
                    {t("Special Scenarios")}
                  </Button>
                  <Button
                    key="contact_us"
                    onClick={() => handleScroll("contact_us")}
                    sx={{ fontSize: "12px", color: "#fff", fontWeight: "bold" }}
                  >
                    {t("Contact us")}
                  </Button>

                  <div style={{ marginTop: "10px" }}>
                    <Button
                      className="poppinsRegularBold"
                      sx={{
                        width: "140px",
                        height: "40px",
                        color: "#060502",
                        border: "4px solid #F5BC01",
                        backgroundImage:
                          "linear-gradient(to right, #FFEA96 , #FFE064)",
                        borderRadius: "6px",
                        fontSize: "13px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        window.open(download_now_url, "_blank");
                      }}
                    >
                      {" "}
                      {t("Download")}{" "}
                    </Button>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <Button
                      className="poppinsRegularBold"
                      sx={{
                        width: "100px",
                        height: "40px",
                        padding: 0,
                        color: "#060502",
                        border: "4px solid #F5BC01",
                        backgroundImage:
                          "linear-gradient(to right, #FFEA96 , #FFE064)",
                        borderRadius: "6px",
                        fontSize: "13px",
                        transition: "background-color 0.3s, color 0.3s",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        window.open(
                          "https://portal.cueballdash.com/",
                          "_blank"
                        );
                      }}
                    >
                      {" "}
                      {t("Login")}{" "}
                    </Button>
                  </div>

                  <Button>
                    <Box
                      sx={{
                        width: "130px",
                        height: "40px",
                        border: "2px solid white",
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <IconButton onClick={handleClick}>
                        <Avatar
                          variant="square"
                          src={currentFlag.src}
                          sx={{ width: 30, height: 20 }}
                        />
                        <Typography
                          sx={{
                            color: "black",
                            marginLeft: 1,
                            fontWeight: "bold",
                          }}
                        >
                          {currentFlag.label}
                        </Typography>
                        {anchorEl ? (
                          <KeyboardArrowUp sx={{ color: "black" }} />
                        ) : (
                          <KeyboardArrowDown sx={{ color: "black" }} />
                        )}
                      </IconButton>
                    </Box>
                  </Button>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "right",
                  }}
                >
                  <Button>
                    <Box
                      sx={{
                        width: "fit-content",
                        height: "40px",
                        border: "2px solid white",
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <IconButton onClick={handleClick}>
                        <Avatar
                          variant="square"
                          src={currentFlag.src}
                          sx={{ width: 20, height: 20 }}
                        />
                        <Typography
                          sx={{
                            color: "black",
                            marginLeft: 1,
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {currentFlag.label}
                        </Typography>
                        {anchorEl ? (
                          <KeyboardArrowUp sx={{ color: "black" }} />
                        ) : (
                          <KeyboardArrowDown sx={{ color: "black" }} />
                        )}
                      </IconButton>
                    </Box>
                  </Button>
                  <Button
                    className="poppinsRegularBold"
                    sx={{
                      width: "fit-content",
                      height: "50px",
                      color: "#060502",
                      border: "6px solid #F5BC01",
                      backgroundImage:
                        "linear-gradient(to right, #FFEA96 , #FFE064)",
                      borderRadius: "6px",
                      fontSize: "13px",
                      transition: "background-color 0.3s, color 0.3s",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      window.open("https://portal.cueballdash.com/", "_blank");
                    }}
                  >
                    {" "}
                    {t("Login")}{" "}
                  </Button>

                  <IconButton
                    sx={{
                      display: { xs: "flex", md: "none" },
                      ml: "auto",
                      color: "white",
                    }}
                    onClick={toggleDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {menuItems}
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ width: "160px", mt: 1 }}
      >
        <MenuItem
          selected={i18n.language === "en"}
          onClick={() => changeLanguage("en")}
        >
          <Avatar
            variant="square"
            src={flag_eng}
            sx={{ width: 30, height: 30 }}
          />
          <Typography sx={{ marginLeft: 1 }}>English</Typography>
        </MenuItem>
        <MenuItem
          selected={i18n.language === "es"}
          onClick={() => changeLanguage("es")}
        >
          <Avatar
            variant="square"
            src={flag_spanish}
            sx={{ width: 30, height: 30 }}
          />
          <Typography sx={{ marginLeft: 1 }}>Spanish</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default AppbarHomw;
