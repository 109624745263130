import { Grid, Button, Alert, Collapse, IconButton, TextField, Typography, CardContent, Box } from '@mui/material';
import * as React from 'react';
import "../stylesheetCss.css"
import { post } from '../../urls/api';
import toastAlert from '../ToastAlert';
import { ClipLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import { FeaturePara, Featureheader, contactUsMessageAlert, contactUsMessageAlertError } from '../../urls/contactusKeys';
import { globalColor } from '../../utils/globalColor';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
function ContactCard() {
    const override = {
        display: "block",
        margin: "0 auto",
    };
    const [loader, setLoader] = React.useState(false)
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')

    const [email, setEmail] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [msgAlert, setMessageAlert] = React.useState('success');
    const theme = useTheme();


    const SendMessage = async () => {
        setLoader(true)

        console.log("Sending Message")
        if (firstName === "" || lastName === "" || email === "" || message === "") {
            toastAlert("error", "Please fill all fields")
            setLoader(false)
        } else {
            // api call 
            try {
                const postData = {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    message: message
                }
                const apiData = await post('contact_us/send_message', postData); // Specify the endpoint you want to call
                console.log(apiData)
                if (apiData.error) {
                    console.log("error true")
                    setLoader(false)
                    // toastAlert("error", "Something went wrong")
                    setMessageAlert('error')
                    setOpen(true)
                } else {
                    setLoader(false)
                    setMessageAlert('success')
                    setOpen(true)
                    // toastAlert("success", "Message Sent . We will contact you soon !")
                    setFirstName('')
                    setLastName('')
                    setEmail('')
                    setMessage('')
                }
            }
            catch (error) {
                setLoader(false)
            }
        }
    }

    const { t, i18n } = useTranslation();

    return (
        <>

            <Grid container
                // id="smallScreenHero"
                style={{
                    // backgroundColor:'#fafafa',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <Grid item xs={12} md={3}>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>


                    <Box
                        style={{
                            // boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
                            padding: '4%'
                        }}>
                        <CardContent style={{ textAlign: 'center' }}>

                            <div
                            // onClick={() => navigate(`/product_detail/${feature.product_id}`)}
                            >
                                <Typography className="poppinsSemiRegularBold"
                                    sx={{
                                        fontSize: {
                                            xs: "30px",
                                            sm: "30px",
                                            md: "44px",
                                        },
                                        fontWeight: 500,
                                        letterSpacing: '2%',
                                        color: '#0C0C0C',
                                        textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                                        // textShadow:'3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                                        marginBlock: '6%', marginBottom: '1%',
                                        width: '100%',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {t(Featureheader)}
                                </Typography>
                                <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '100%', marginTop: '2%', fontSize: '18px' }}>
                                    {t(FeaturePara)}
                                </Typography>
                                <Box className="avatar-large-screen">
                                    {/* <Box style={{display:'flex'}}> */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Box style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                <Typography className='poppinsSemiRegularBold'
                                                    variant="h6" style={{ fontSize: '18px', fontWeight: 500 }}>
                                                    {t("First Name")}
                                                </Typography>
                                                <TextField
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    required
                                                    style={{ backgroundColor: '#F2F2F2', width: "auto", height: '55px', borderRadius: '5px', border: '1px solid #F2F2F2' }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                <Typography className='poppinsSemiRegularBold'
                                                    variant="h6" style={{ fontSize: '18px', fontWeight: 500, marginLeft: '10px', }}>
                                                    {t("Last Name")}
                                                </Typography>
                                                <TextField
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    required
                                                    style={{ marginLeft: '10px', backgroundColor: '#F2F2F2', width: "auto", height: '55px', borderRadius: '5px', border: '1px solid #F2F2F2' }} />
                                            </Box>
                                        </Grid>
                                    </Grid>




                                    {/* </Box> */}
                                </Box>
                                <Box className="avatar-small-screen" >
                                    <Typography className='poppinsSemiRegularBold'
                                        variant="h6" style={{ textAlign: 'left', fontSize: '18px', fontWeight: 500 }}>
                                        {t("First Name")}
                                    </Typography>
                                    <TextField
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        style={{ backgroundColor: '#F2F2F2', width: '100%', height: '55px', borderRadius: '5px', border: '1px solid #F2F2F2' }} />

                                    <Typography className='poppinsSemiRegularBold'
                                        variant="h6" style={{ textAlign: 'left', fontSize: '18px', fontWeight: 500 }}>
                                        {t("Last Name")}
                                    </Typography>
                                    <TextField
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        style={{ backgroundColor: '#F2F2F2', width: '100%', height: '55px', borderRadius: '5px', border: '1px solid #F2F2F2' }} />
                                </Box>
                                <Typography className='poppinsSemiRegularBold'
                                    variant="h6" style={{ marginTop: '2%', fontSize: '18px', fontWeight: 500, textAlign: 'left' }}>
                                    {t("Email Address")}
                                </Typography>
                                <TextField type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={{ backgroundColor: '#F2F2F2', width: "100%", height: '55px', borderRadius: '5px', border: '1px solid #F2F2F2' }} />
                                <Typography className='poppinsSemiRegularBold'
                                    variant="h6" style={{ marginTop: '2%', fontSize: '18px', fontWeight: 500, textAlign: 'left' }}>
                                    {t("Message")}
                                </Typography>
                                <TextField type="text"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                    style={{ backgroundColor: '#F2F2F2', width: "100%", borderRadius: '5px' }} />
                                <div style={{ marginTop: '1%' }}>
                                    <Collapse in={open}>
                                        <Alert variant="filled"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {msgAlert === "success" ? <>
                                                {contactUsMessageAlert}
                                            </> :
                                                <>
                                                    {contactUsMessageAlertError}  </>}

                                        </Alert>
                                    </Collapse>
                                </div>

                                <Button
                                    onClick={() => SendMessage()}
                                    disabled={loader}
                                    startIcon={loader ?
                                        <ClipLoader
                                            color="Gray"
                                            loading={loader}
                                            cssOverride={override}
                                            size={10}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        /> : null}
                                    className='poppinsRegularBold'
                                    sx={{
                                        width: '30%',
                                        [theme.breakpoints.down('sm')]: {
                                            width: '100%', // Change the width to 100% on small screens
                                        },
                                        marginTop: '2%',
                                        color: '#060502',
                                        border: '6px solid #F5BC01',
                                        // linear gradient 
                                        backgroundImage: 'linear-gradient(to right, #FFEA96 , #FFE064)',
                                        // bgcolor: ,
                                        borderRadius: '6px',
                                        //center button
                                        fontSize: '16px',
                                        transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                        // '&:hover': {
                                        //     bgcolor: "white",
                                        //     color: globalColor,
                                        //     border: '1px solid #03356b', // Change text color to blue on hover
                                        //     transitionDelay: '0.3s', // Add transition delay for hover
                                        // },
                                        // disable button css 
                                        '&.Mui-disabled': {
                                            color: 'white',
                                            backgroundColor: globalColor,
                                            opacity: 0.6,
                                            cursor: 'not-allowed',
                                            border: 'none',
                                        },
                                        // marginLeft: '10px',
                                        marginBlock: '4%',
                                        textTransform: 'capitalize'

                                    }}>{t("Send Message")}</Button>

                            </div>

                        </CardContent>

                    </Box>
                    {/* </Card> */}


                </Grid>
                <Grid item xs={12} md={3} id="smallScreenImageLarge">
                </Grid>

            </Grid>
        </>
    );
}

export default ContactCard;