// import logo from './logo.svg';
import * as React from 'react';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';

function App() {
  const firstSectionRef = React.useRef(null);

  React.useEffect(() => {
    // When the component mounts, scroll to the first section
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts
  return (
    <>
      <Routes >
        <Route path={`/`} element={<HomePage />} />

      </Routes>
      <div>
        <Toaster
            position="top-center"
        />
    </div>
    </>
  );
}

export default App;
