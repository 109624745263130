// import logo from './logo.svg';
import * as React from 'react';
import '../App.css';
import AppbarHomw from '../components/Appbar';
import HeroSection from '../components/Hero';
import Features2 from '../components/Products2';
import Footer from '../components/Footer';
import ProductsData from '../components/Products1';
import ContactCard from '../components/ContactCard';
import SpecialScenario from '../components/SpecialScenario';
import Mobile1 from '../components/Mobile1';
import Mobile2 from '../components/Mobile2';
import StartedScreen from '../components/startedScreen';

function HomePage() {


  return (
    <>

      {/* Appbar  */}
      <div style={{ backgroundColor: 'white', overflowX: 'hidden ' }} 
      >
        <AppbarHomw />
        <div id="home"> 
        <HeroSection />
        </div>
        <div id="about"> 
       
        <ProductsData productALL="firstFour" />
        </div>
        <div id="features"> 

        <Features2 productALL="firstFour" />
        </div>
        <div id="testimonials"> 
        <SpecialScenario productALL="firstFour" />
        </div>
        <Mobile1/>
        <Mobile2 />
        <div id="contact_us"> 

        <ContactCard />
        </div>
        <StartedScreen/>
        <Footer />

      </div>


    </>
  );
}

export default HomePage;
