import { Avatar, Box,  Grid, Skeleton, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ScrollAnimation from 'react-animate-on-scroll';
import checkMark from "../../assets/check-mark.png"
import why1 from "../../assets/why1.png"
import why2 from "../../assets/why2.png"
import { cardHead1, cardHead2, cardHead3, cardHead4, cardPara1, cardPara2, cardPara3, cardPara4, productsHead2, productsHead3 } from '../../urls/contactusKeys';
import { useTranslation } from 'react-i18next';

function Features2({ productALL }) {
    const [loader, setLoader] = React.useState(false)

    const { t, i18n } = useTranslation();

    return (
        <>
            {loader ? <>
                <Grid container spacing={2} pt={5} style={{ backgroundColor: "rgb(250 250 250)" }}>
                    {/* For variant="text", adjust the height via font-size */}
                    <Grid item xs={12} md={1}>
                    </Grid>
                    <Grid item xs={12} md={10} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} align="center">
                                {/* <Typography className='poppinsSemiRegularBold' variant='h4' style={{ color: '#041d34' }}>
                            Our Product
                            </Typography> */}
                                <Typography id="productHeader" className='poppinsSemiRegularBold' variant='h2'
                                    sx={{
                                        fontSize: {
                                            xs: "30px",
                                            sm: "30px",
                                            md: "44px",
                                        },
                                        fontWeight: 500,
                                        letterSpacing: '2%',
                                        color: '#0C0C0C',
                                        // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                                        marginBlock: '6%', marginBottom: '1%',
                                        width: '100%',
                                        textTransform: 'capitalize',
                                    }}>
                                    {t(productsHead2)}
                                </Typography>
                                <Typography variant='h6' className='lexendLight' style={{ color: '#888888', fontweight: 700,
                                //  maxWidth: '35%'
                                 maxWidth: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '35%'
                                }, }}>
                                   {t(productsHead3)}
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant="rectangular" height={300} />

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant="rectangular" height={300} />

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant="rectangular" height={300} />

                            </Grid>
                        </Grid>
                        {/* For other variants, adjust the size with `width` and `height` */}
                        {/* <Skeleton variant="rectangular" />
                        <Skeleton variant="rounded" width={210} height={60} /> */}
                    </Grid>
                    <Grid item xs={12} md={1}>
                    </Grid>
                </Grid>
            </> :
                <>
                    <Grid container spacing={2} style={{ backgroundColor: "rgb(250 250 250)", paddingBlock: '2%',position:'relative' }}>
                        <span style={{ position: 'absolute', bottom:0, left: 0 }}>
                        <Avatar src={why1} alt="top"
                            variant='square'
                            style={{
                                width: '208px',
                                height: 'auto',

                            }} /></span>
                             <span style={{ position: 'absolute', top:0, right: 0 }}>
                        <Avatar src={why2} alt="top"
                            variant='square'
                            style={{
                                width: '208px',
                                height: 'auto',

                            }} /></span><Grid item xs={12} md={1}>
                        </Grid>
                        <Grid item xs={12} md={10} style={{position:'relative',marginBottom:'60px'}}>
                        
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} align="center">
                                    {/* <Typography className='poppinsSemiRegularBold' variant='h4' style={{ color: '#041d34' }}>
                            Our Product
                            </Typography> */}
                                    <Typography id="productHeader" className='poppinsSemiRegularBold' variant='h2'
                                        sx={{
                                            fontSize: {
                                                xs: "30px",
                                                sm: "30px",
                                                md: "44px",
                                            },
                                            fontWeight: 500,
                                            letterSpacing: '2%',
                                            color: '#0C0C0C',
                                            // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                                            marginBlock: '6%', marginBottom: '1%',
                                            width: '100%',
                                            textTransform: 'capitalize',
                                        }}>
                                        {t(productsHead2)}
                                    </Typography>
                                    <Typography variant='h6' className='lexendLight'
                                     style={{ color: '#888888', fontweight: 700,
                                     maxWidth: {
                                        xs: '100%',
                                        sm: '100%',
                                        md: '35%'
                                    }, }}>
                                       {t(productsHead3)}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} md={3} mt={2} align="center">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <Box>
                                            <Avatar src={checkMark}
                                                variant='square'
                                                style={{
                                                    width: '102px',
                                                    height: 'auto',

                                                }} />
                                            <Typography className='poppinsSemiRegularBold' variant='h2'
                                                sx={{
                                                    fontSize: "22px",
                                                    fontWeight: 500,
                                                    letterSpacing: '2%',
                                                    color: '#F5BC01',
                                                    marginBlock: '6%', marginBottom: '1%',
                                                    width: '100%',
                                                    textTransform: 'capitalize',
                                                }}>
                                                {t(cardHead1)}
                                            </Typography>
                                            <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '80%', fontSize: '19px' }}>
                                               {t(cardPara1)}
                                            </Typography>
                                        </Box>

                                        {/* End  */}
                                    </ScrollAnimation>

                                </Grid>
                                <Grid item xs={12} md={3} mt={2} align="center">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <Box>
                                            <Avatar src={checkMark}
                                                variant='square'
                                                style={{
                                                    width: '102px',
                                                    height: 'auto',

                                                }} />
                                            <Typography className='poppinsSemiRegularBold' variant='h2'
                                                sx={{
                                                    fontSize: "22px",
                                                    fontWeight: 500,
                                                    letterSpacing: '2%',
                                                    color: '#F5BC01',
                                                    marginBlock: '6%', marginBottom: '1%',
                                                    width: '100%',
                                                    textTransform: 'capitalize',
                                                }}>
                                                {t(cardHead2)}
                                            </Typography>
                                            <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '80%', fontSize: '19px' }}>
                                                {t(cardPara2)}
                                            </Typography>
                                        </Box>

                                        {/* End  */}
                                    </ScrollAnimation>

                                </Grid>
                                <Grid item xs={12} md={3} mt={2} align="center">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <Box>
                                            <Avatar src={checkMark}
                                                variant='square'
                                                style={{
                                                    width: '102px',
                                                    height: 'auto',

                                                }} />
                                            <Typography className='poppinsSemiRegularBold' variant='h2'
                                                sx={{
                                                    fontSize: "22px",
                                                    fontWeight: 500,
                                                    letterSpacing: '2%',
                                                    color: '#F5BC01',
                                                    marginBlock: '6%', marginBottom: '1%',
                                                    width: '100%',
                                                    textTransform: 'capitalize',
                                                }}>
                                                {t(cardHead3)}
                                            </Typography>
                                            <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '80%', fontSize: '19px' }}>
                                               {t(cardPara3)}
                                            </Typography>
                                        </Box>

                                        {/* End  */}
                                    </ScrollAnimation>

                                </Grid>
                                <Grid item xs={12} md={3} mt={2} align="center">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <Box>
                                            <Avatar src={checkMark}
                                                variant='square'
                                                style={{
                                                    width: '102px',
                                                    height: 'auto',

                                                }} />
                                            <Typography className='poppinsSemiRegularBold' variant='h2'
                                                sx={{
                                                    fontSize: "22px",
                                                    fontWeight: 500,
                                                    letterSpacing: '2%',
                                                    color: '#F5BC01',
                                                    marginBlock: '6%', marginBottom: '1%',
                                                    width: '100%',
                                                    textTransform: 'capitalize',
                                                }}>
                                                {t(cardHead4)}
                                            </Typography>
                                            <Typography variant='h6' className='poppinsLight' style={{ color: '#888888', fontweight: 700, maxWidth: '80%', fontSize: '19px' }}>
                                               {t(cardPara4)}
                                            </Typography>
                                        </Box>

                                        {/* End  */}
                                    </ScrollAnimation>

                                </Grid>



                            </Grid>
                          
                        </Grid>




                        <Grid item xs={12} md={1}>
                        </Grid>



                    </Grid>
                </>}

        </>
    );
}

export default Features2;