import { Avatar, Grid, Typography } from '@mui/material';
import * as React from 'react';
// import "../../App.css"
import imagesAbout from "../../assets/mob1.png"
import why1 from "../../assets/spGroup2.png"
import why2 from "../../assets/spGroup.png"
import "../stylesheetCss.css"
import { mobile1 } from '../../urls/contactusKeys';
import { useTranslation } from 'react-i18next';
function Mobile1() {

    const { t, i18n } = useTranslation();

    return (
        <>

            <Grid container spacing={2} pt={10} style={{
                position: 'relative',
                // background Image 
                // backgroundImage: `url(${mob1Mask})`,
                backgroundColor: "transparent",
                marginBlock: '2%', marginBottom: '6%'
            }}>
                <span className='avatar-large-screen' style={{ position: 'absolute', bottom: 0, left: 0 }}>
                    <Avatar src={why1} alt="top"

                        variant='square'
                        style={{
                            width: '208px',
                            height: 'auto',

                        }} /></span>
                <span className='avatar-large-screen' style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Avatar src={why2} alt="top"

                        variant='square'
                        style={{
                            width: '208px',
                            height: 'auto',

                        }} /></span>
                <Grid item xs={12} md={1} >
                </Grid>
                <Grid className='avatar-large-screen' item xs={12} md={5} style={{ position: 'relative' }}>
                    <Avatar

                        src={imagesAbout} alt="top"
                        variant='square'
                        style={{
                            width: '600px',
                            height: 'auto',

                        }} />




                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


                    <Typography variant='h6' className='poppinsRegularBold'
                        sx={{
                            // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                            color: '#0C0C0C',
                            fontweight: 700, maxWidth: '80%',
                            fontSize: {
                                xs: "20px",
                                sm: "20px",
                                md: "30px",
                            },
                        }}>
                        {t(mobile1)}
                    </Typography>




                </Grid>




            </Grid>


        </>
    );
}

export default Mobile1;