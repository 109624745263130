import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
const resources = {
    en: {
        translation: {
            "Home": "Home",
            "About Us": "About Us",
            "Features": "Features",
            "Special Scenarios": "Special Scenarios",
            "Contact us": "Contact us",
            "Your Gateway to Exciting Live Prediction Gaming!": "Your Gateway to Exciting Live Prediction Gaming!",
            "Cue Ball opens the gateway to the thrilling world of live prediction gaming. Dive in, make your predictions, witness real-time pool matches, and savor the sweet taste of victory with exciting prizes. It's that simple – experience the pulse of the game!": "Cue Ball opens the gateway to the thrilling world of live prediction gaming. Dive in, make your predictions, witness real-time pool matches, and savor the sweet taste of victory with exciting prizes. It's that simple – experience the pulse of the game!",
            "Active Users": "Active Users",
            "Downloads": "Downloads",
            "Games Played": "Games Played",
            "About Cue-Ball": "About Cue-Ball",
            "Step into the captivating world of Cue Ball, where it transcends beyond a mere game—it's an immersive experience! Brace yourself for the exhilarating realm of live prediction gaming, where you become the oracle, foreseeing the winning ball in real-time pool matches unfolding at various locations.": "Step into the captivating world of Cue Ball, where it transcends beyond a mere game—it's an immersive experience! Brace yourself for the exhilarating realm of live prediction gaming, where you become the oracle, foreseeing the winning ball in real-time pool matches unfolding at various locations.",
            "Our interactive platform is more than a gaming interface; it's a portal to the heart of live events. Feel the pulse of the game as you make predictions, engage with real-time pool action, and elevate your gaming journey to unprecedented heights. At Cue Ball, we redefine gaming, turning it into a dynamic, interactive adventure that brings the thrill of live events right to your fingertips.": "Our interactive platform is more than a gaming interface; it's a portal to the heart of live events. Feel the pulse of the game as you make predictions, engage with real-time pool action, and elevate your gaming journey to unprecedented heights. At Cue Ball, we redefine gaming, turning it into a dynamic, interactive adventure that brings the thrill of live events right to your fingertips.",
            "Why Cue-Ball?": "Why Cue-Ball?",
            "Dive into a world of innovation and seamless functionality with our powerhouse features.": "Dive into a world of innovation and seamless functionality with our powerhouse features.",
            "Live Gaming Experience": "Live Gaming Experience",
            "Experience the excitement of live prediction gaming with real pool games played at various locations.": "Experience the excitement of live prediction gaming with real pool games played at various locations.",

            "Fair and Transparent": "Fair and Transparent",
            "Our platform ensures fairness in gameplay and transparent distribution of winnings.": "Our platform ensures fairness in gameplay and transparent distribution of winnings.",

            "Secure Transactions": "Secure Transactions",
            "Your payments and withdrawals are securely processed through trusted payment gateways like PayPal.": "Your payments and withdrawals are securely processed through trusted payment gateways like PayPal.",

            "User-Friendly Interface": "User-Friendly Interface",
            "Enjoy a seamless and intuitive user interface, making your gaming experience enjoyable and hassle-free.": "Enjoy a seamless and intuitive user interface, making your gaming experience enjoyable and hassle-free.",

            "White Ball Wins": "White Ball Wins",
            "In this scenario, all entry fees go into the admin's account.": "In this scenario, all entry fees go into the admin's account.",

            "No Winner": "No Winner",
            "If no player selects the winning ball, the game restarts without entry fees.": "If no player selects the winning ball, the game restarts without entry fees.",

            "8th or 9th Ball Wins": "8th or 9th Ball Wins",
            "If the 8th ball wins, users who selected balls 1 to 7 are winners. If the 9th ball wins, users who selected balls 9 to 15 are winners.": "If the 8th ball wins, users who selected balls 1 to 7 are winners. If the 9th ball wins, users who selected balls 9 to 15 are winners.",

            "Check recent victories. See players who predicted the winning ball take their place in the Winners' Circle. Will your name be next? Stay tuned and revel in the triumphs!🎱🌟": "Check recent victories. See players who predicted the winning ball take their place in the Winners' Circle. Will your name be next? Stay tuned and revel in the triumphs!🎱🌟",

            "History screen provides a timeline of your gaming journey, showcasing the games you've participated in.📜  Wallet screen is your financial hub, where every winning finds its place. 🏆💸": "History screen provides a timeline of your gaming journey, showcasing the games you've participated in.📜  Wallet screen is your financial hub, where every winning finds its place. 🏆💸",

            "Got questions, suggestions, or just want to chat? We're here for you. We value your feedback and can't wait to hear from you!": "Got questions, suggestions, or just want to chat? We're here for you. We value your feedback and can't wait to hear from you!",

            "First Name": "First Name",
            "Last Name": "Last Name",
            "Email Address": "Email Address",
            "Message": "Message",
            "Send Message": "Send Message",

            "Immerse yourself in live pool games happening at various locations.": "Immerse yourself in live pool games happening at various locations.",

            "Get Started Today!": "Get Started Today!",
            "Join Cue Ball now and embark on a journey of thrilling live prediction games. Sign up, make your predictions, and compete with others for exciting prizes!": "Join Cue Ball now and embark on a journey of thrilling live prediction games. Sign up, make your predictions, and compete with others for exciting prizes!",
            "Download Now!": "Download Now!",
            "Login": "Login",

            "Shape your legacy in our 8-ball universe, where victories roll in spheres!": "Shape your legacy in our 8-ball universe, where victories roll in spheres!",


            "Create You're Account": "Create You're Account",
            "To play it’s simple just create your account and stay tuned to our lives on TikTok, play and chose your ball when the host open the game and watch the race live, good luck": "To play it’s simple just create your account and stay tuned to our lives on TikTok, play and chose your ball when the host open the game and watch the race live, good luck"
        },
    },
    es: {
        translation: {
            "Home": "Hogar",
            "About Us": "Sobre nosotras",
            "Features": "Características",
            "Special Scenarios": "Escenarios especiales",
            "Contact us": "Contacta con nosotras",
            "Your Gateway to Exciting Live Prediction Gaming!": "¡Tu puerta de entrada a emocionantes juegos de predicción en vivo!",
            "Cue Ball opens the gateway to the thrilling world of live prediction gaming. Dive in, make your predictions, witness real-time pool matches, and savor the sweet taste of victory with exciting prizes. It's that simple – experience the pulse of the game!": "Cue Ball abre la puerta al apasionante mundo de los juegos de predicción en vivo. Sumérgete, haz tus predicciones, presencia partidos de billar en tiempo real y saborea el dulce sabor de la victoria con emocionantes premios. Es así de simple: ¡siente el pulso del juego!",
            "Active Users": "Usuarias activas",
            "Downloads": "Descargas",
            "About Cue-Ball": "Acerca de la bola blanca",
            "Games Played": "Juegos jugados",
            "Step into the captivating world of Cue Ball, where it transcends beyond a mere game—it's an immersive experience! Brace yourself for the exhilarating realm of live prediction gaming, where you become the oracle, foreseeing the winning ball in real-time pool matches unfolding at various locations.": "Adéntrate en el cautivador mundo de Cue Ball, donde trasciende más allá de un simple juego: ¡es una experiencia inmersiva! Prepárate para el emocionante reino de los juegos de predicción en vivo, donde te conviertes en el oráculo, previendo la bola ganadora en partidos de billar en tiempo real que se desarrollan en varios lugares.",
            "Our interactive platform is more than a gaming interface; it's a portal to the heart of live events. Feel the pulse of the game as you make predictions, engage with real-time pool action, and elevate your gaming journey to unprecedented heights. At Cue Ball, we redefine gaming, turning it into a dynamic, interactive adventure that brings the thrill of live events right to your fingertips.": "Nuestra plataforma interactiva es más que una interfaz de juego; es un portal al corazón de los eventos en vivo. Siente el pulso del juego mientras haces predicciones, participas en la acción del billar en tiempo real y elevas tu viaje de juego a alturas sin precedentes. En Cue Ball, redefinimos los juegos, convirtiéndolos en una aventura dinámica e interactiva que pone la emoción de los eventos en vivo al alcance de tu mano.",
            "Why Cue-Ball?": "¿Por qué bola blanca?",
            "Dive into a world of innovation and seamless functionality with our powerhouse features.": "Sumérgete en un mundo de innovación y funcionalidad perfecta con nuestras potentes funciones.",
            "Live Gaming Experience": "Experiencia de juego en vivo",
            "Experience the excitement of live prediction gaming with real pool games played at various locations.": "Experimente la emoción de los juegos de predicción en vivo con juegos de billar reales que se juegan en varios lugares.",

            "Fair and Transparent": "Justo y transparente",
            "Our platform ensures fairness in gameplay and transparent distribution of winnings.": "Nuestra plataforma garantiza la equidad en el juego y la distribución transparente de las ganancias.",

            "Secure Transactions": "Transacciones seguras",
            "Your payments and withdrawals are securely processed through trusted payment gateways like PayPal.": "Sus pagos y retiros se procesan de forma segura a través de pasarelas de pago confiables como PayPal.",

            "User-Friendly Interface": "Interfaz fácil de usar",
            "Enjoy a seamless and intuitive user interface, making your gaming experience enjoyable and hassle-free.": "Disfrute de una interfaz de usuario intuitiva y fluida, que hace que su experiencia de juego sea placentera y sin complicaciones.",

            "White Ball Wins": "La bola blanca gana",
            "In this scenario, all entry fees go into the admin's account.": "En este escenario, todas las tarifas de entrada van a la cuenta del administrador.",

            "No Winner": "Ningún ganador",
            "If no player selects the winning ball, the game restarts without entry fees.": "Si ningún jugador selecciona la bola ganadora, el juego se reinicia sin cuotas de inscripción.",

            "8th or 9th Ball Wins": "Gana la octava o novena bola",
            "If the 8th ball wins, users who selected balls 1 to 7 are winners. If the 9th ball wins, users who selected balls 9 to 15 are winners.": "Si gana la octava bola, los usuarios que seleccionaron las bolas 1 a 7 son ganadores. Si gana la novena bola, los usuarios que seleccionaron las bolas 9 a 15 serán ganadores.",

            "Check recent victories. See players who predicted the winning ball take their place in the Winners' Circle. Will your name be next? Stay tuned and revel in the triumphs!🎱🌟": "Consulta victorias recientes. Vea a los jugadores que predijeron la bola ganadora ocupar su lugar en el Círculo de Ganadores. ¿Será tu nombre el próximo? ¡Estén atentos y disfruten de los triunfos!🎱🌟",

            "History screen provides a timeline of your gaming journey, showcasing the games you've participated in.📜  Wallet screen is your financial hub, where every winning finds its place. 🏆💸": "La pantalla de historial proporciona una línea de tiempo de su viaje de juego, mostrando los juegos en los que ha participado.📜 La pantalla de billetera es su centro financiero, donde cada ganancia encuentra su lugar. 🏆💸",

            "Got questions, suggestions, or just want to chat? We're here for you. We value your feedback and can't wait to hear from you!": "¿Tienes preguntas, sugerencias o simplemente quieres charlar? Estamos aquí para ti. ¡Valoramos sus comentarios y estamos ansiosos por saber de usted!",

            "First Name": "Nombre de pila",
            "Last Name": "Apellido",
            "Email Address": "Dirección de correo electrónico",
            "Message": "Mensaje",
            "Send Message": "Enviar mensaje",

            "Immerse yourself in live pool games happening at various locations.": "Sumérgete en juegos de billar en vivo que se desarrollan en varios lugares.",

            "Get Started Today!": "¡Empiece hoy!",
            "Join Cue Ball now and embark on a journey of thrilling live prediction games. Sign up, make your predictions, and compete with others for exciting prizes!": "Únase a Cue Ball ahora y embárquese en un viaje de emocionantes juegos de predicción en vivo. ¡Regístrese, haga sus predicciones y compita con otros por premios emocionantes!",
            "Download Now!": "¡Descárgalo ahora!",
            "Login": "Acceso",

            "Shape your legacy in our 8-ball universe, where victories roll in spheres!": "¡Da forma a tu legado en nuestro universo de 8 bolas, donde las victorias se suceden en esferas!",

            "Create You're Account": "Crea tu cuenta",
            "To play it’s simple just create your account and stay tuned to our lives on TikTok, play and chose your ball when the host open the game and watch the race live, good luck": "Para jugar es sencillo solo crea tu cuenta y mantente atento a nuestros vidas en TikTok, juega y elige tu pelota cuando el anfitrión abra el juego y vea la carrera en vivo, buena suerte."

        }

    }
};

const savedLang = localStorage.getItem('lang');

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLang || 'es',
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

