import { Avatar, Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import "../stylesheetCss.css"
import imagesAbout from "../../assets/startedSectionMob.png"
import getStartedSectionImages from "../../assets/getStartedSectionImages.png"
import appstore from "../../assets/appstore.png"
import googlePlay from "../../assets/googlePlay.png"
import mask from "../../assets/mask.png"
import { modalHeader, modalHeader1, sendPaymentText } from '../../urls/contactusKeys';
import { appStoreBtn, downloadNowBtn, googlePlayBtn } from '../../urls/apikeys';
import { get } from '../../urls/api';
import { useTranslation } from 'react-i18next';

function StartedScreen() {
    const theme = useTheme();
    const [download_now_url, setDownload_now_url] = React.useState('')
    const [play_store_url, setPlay_store_url] = React.useState('')
    const [app_store_url, setApp_store_url] = React.useState('')
    const getSocialLinks = async () => {

        //call api  privacy_policy/getSocialLinks to get all in console 
        const apiData = await get('privacy_policy/getDownloadButtons'); // Specify the endpoint you want to call
        console.log("Active Blogs Details")
        console.log(apiData)
        if (apiData.error) {
        } else {
            console.log("button urls")
            setDownload_now_url(apiData.data.download_now_url)
            setPlay_store_url(apiData.data.play_store_url)
            setApp_store_url(apiData.data.app_store_url)

        }

    }
    React.useEffect(() => {
        getSocialLinks()
    }, [])

    const { t, i18n } = useTranslation();

    return (
        <>
            <Divider
                style={{
                    width: '80%', height: '2px', backgroundColor: '#F5BC01'
                    // center the divider by adding margin
                    , margin: 'auto', marginTop: '3%'

                }}
            />
            <Grid container spacing={2} pt={10} style={{ backgroundColor: "transparent", marginBlock: '2%', marginBottom: '6%' }}>


                <Grid item xs={12} md={6} style={{
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center'
                }}>


                    <Typography variant='h6' className='poppinsRegularBold' sx={{
                        // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                        color: '#0C0C0C', fontWeight: 700, maxWidth: ' 90%',
                        fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "40px",
                        }
                    }}>
                        {t(modalHeader)}
                    </Typography>
                    <div className='avatar-large-screen'  >
                        <Avatar
                            src={imagesAbout} alt="top"
                            variant='square'
                            style={{
                                width: '630px',
                                height: 'auto',

                            }} />
                    </div>




                </Grid>
                <Grid item xs={12} md={5} className='avatar-large-screen' style={{ position: 'relative' }}>

                    <Avatar
                        src={getStartedSectionImages} alt="top"
                        variant='square'
                        style={{
                            width: '900px',
                            height: 'auto',

                        }} />



                </Grid>
                <Grid item xs={12} md={1} >
                </Grid>
                <Grid item xs={12} md={12} style={{
                    // backgroundImage cover 
                    backgroundImage: `url(${mask})`
                    , marginTop: -200
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={1} md={6}>
                        </Grid>
                        <Grid item xs={10} md={5}>
                            <Typography className='poppinsSemiRegularBold' variant='h2'
                                sx={{
                                    fontSize: {
                                        xs: "25px",
                                        sm: "25px",
                                        md: "44px",
                                    },
                                    fontWeight: 500,
                                    letterSpacing: '2%',
                                    color: '#0C0C0C',
                                    // textShadow: ' 3px 3px 1px rgba(151,151,151,0.2), 0 0 5px #F5BC01',
                                    marginBlock: '6%', marginBottom: '1%',
                                    width: '100%',
                                    textTransform: 'capitalize',
                                }}>
                                {t(sendPaymentText)}
                            </Typography>
                            <Typography variant='h6'
                                className='lexendLight'
                                style={{
                                    color: '#888888',
                                    fontweight: 700, maxWidth: '100%'
                                }}>
                                {t(modalHeader1)}
                            </Typography>
                            <Button
                                // onClick={() => SendMessage()}
                                // disabled={loader}
                                // startIcon={loader ?
                                //     <ClipLoader
                                //         color="Gray"
                                //         // loading={loader}
                                //         cssOverride={override}
                                //         size={10}
                                //         aria-label="Loading Spinner"
                                //         data-testid="loader"
                                //     /> : null}
                                className='poppinsRegularBold'
                                sx={{
                                    width: '30%',
                                    [theme.breakpoints.down('sm')]: {
                                        width: '100%', // Change the width to 100% on small screens
                                    },
                                    marginTop: '2%',
                                    marginBottom: '2%',
                                    color: '#060502',
                                    border: '6px solid #F5BC01',
                                    // linear gradient 
                                    backgroundImage: 'linear-gradient(to right, #FFEA96 , #FFE064)',
                                    // bgcolor: ,
                                    borderRadius: '6px',
                                    //center button
                                    fontSize: '16px',
                                    transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                    // '&:hover': {
                                    //     bgcolor: "white",
                                    //     color: globalColor,
                                    //     border: '1px solid #03356b', // Change text color to blue on hover
                                    //     transitionDelay: '0.3s', // Add transition delay for hover
                                    // },
                                    // disable button css 
                                    // '&.Mui-disabled': {
                                    //     color: 'white',
                                    //     backgroundColor: globalColor,
                                    //     opacity: 0.6,
                                    //     cursor: 'not-allowed',
                                    //     border: 'none',
                                    // },

                                    textTransform: 'capitalize'

                                }}
                                onClick={() => {
                                    window.open(download_now_url, '_blank');
                                }}
                            >{t("Download Now!")}</Button>
                            <div className="avatar-small-screen">


                                {/* <Box
                                    style={{ display: 'flex', flexDirection: 'column', marginBlock: '4%' }}>
                                    <Avatar
                                    onClick={()=>{
                                        window.open(play_store_url, '_blank');
                                    }}
                                        src={googlePlay} alt="top"
                                        variant='square'
                                        style={{
                                            width: '220px',
                                            height: 'auto',

                                        }} />
                                    <Avatar
                                     onClick={()=>{
                                        window.open(app_store_url, '_blank');
                                    }}
                                        src={appstore} alt="top"
                                        variant='square'
                                        style={{
                                            width: '220px',
                                            height: 'auto',

                                        }} />

                                </Box> */}
                            </div>
                            <div className="avatar-large-screen">
                                {/* <Box style={{ display: 'flex', marginBlock: '4%' }}>
                                    <Avatar
                                     onClick={()=>{
                                        window.open(googlePlayBtn, '_blank');
                                    }}
                                        src={googlePlay} alt="top"
                                        variant='square'
                                        style={{
                                            width: '220px',
                                            height: 'auto',

                                        }} />
                                    <Avatar
                                     onClick={()=>{
                                        window.open(appStoreBtn, '_blank');
                                    }}
                                        src={appstore} alt="top"
                                        variant='square'
                                        style={{
                                            marginLeft: '20px',
                                            width: '220px',
                                            height: 'auto',

                                        }} />

                                </Box> */}
                            </div>

                        </Grid>
                    </Grid>
                </Grid>




            </Grid>


        </>
    );
}

export default StartedScreen;