import { Avatar, Grid, Typography,Stack } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import './stylesheet.css'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { emailKey, footerNote, locationKey, phoneNoKey } from '../../urls/contactusKeys';
import footerLogo from "../../assets/footerLogo.png"
import fbIcon from "../../assets/fbIcon.png"
import instaIcon from "../../assets/instaIcon.png"
import linkedinIcon from "../../assets/linkedinIcon.png"
import twitterIcon from "../../assets/twitterIcon.png"
import { fbLink, instaLink, linkedInLink, twitterLink } from '../../urls/apikeys';
import { get } from '../../urls/api';
import { useTranslation } from 'react-i18next';
function Footer() {
    const [fackbook_url, setFackbook_url] = React.useState('')
    const [instagram_url, setInstagram_url] = React.useState('')
    const [twitter_url, setTwitter_url] = React.useState('')
    const [linkedin_url, setLinkedin_url] = React.useState('')
    const [supportEmail, setSupportEmail] = React.useState('')
    const [supportPhone, setSupportPhone] = React.useState('')
    const [supportAddress, setSupportAddress] = React.useState('')
    const getSocialLinks = async() => {

        //call api  privacy_policy/getSocialLinks to get all in console 
        const apiData = await get('privacy_policy/getSocialLinks'); // Specify the endpoint you want to call
        console.log("Active Blogs Details")
        console.log(apiData)
        if (apiData.error) {
        } else {
            console.log("Active Blogs Details")
            setFackbook_url(apiData.data.facebook_url)
            setInstagram_url(apiData.data.instagram_url)
            setTwitter_url(apiData.data.twitter_url)
            setLinkedin_url(apiData.data.linkedin_url)
           
        }

    }
const getSupportData = async() => {
     //call api  privacy_policy/getSocialLinks to get all in console 
     const apiData = await get('privacy_policy/getSupportEmail'); // Specify the endpoint you want to call
     console.log("Support details")
     console.log(apiData)
     if (apiData.error) {
     } else {
        setSupportEmail(apiData.data.email)
        setSupportPhone(apiData.data.phone_no)
        setSupportAddress(apiData.data.address)
     }

}   
 React.useEffect(() => {
        // get social links 
        getSocialLinks()
        getSupportData()
    }, [])

    const { t, i18n } = useTranslation();

    return (
        <>

            <Grid container spacing={2} style={{ marginBlock: '2%',marginTop:"-100px" }}>
                {/* <Grid item xs={1} md={1}></Grid> */}
                <Grid item xs={12} md={12} align="center">
                    {/* <div style={{backgroundColor:'white',width:'50%',borderRadius:'10px'}}> */}
                    <Avatar sx={{ width: "157px", height: 'auto' }} alt="Logo" src={footerLogo} variant='square' />

                    {/* </div> */}
                    <Typography variant='h6' className='poppinsLight' style={{ fontSize: '19px', color: '#060502', fontweight: 100, maxWidth: '85%' }}>
                        {t(footerNote)}
                    </Typography>

                </Grid>
                {/* <Grid item xs={1} md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            md: 'row'
                        },
                    }}
                    id="footernav" >


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', padding: 0 }}>
                    </div>


                </Grid>
                <Grid item xs={11} md={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'

                    }}
                    id="footernav">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', padding: 0 }}>

                        <span
                            onClick={() => window.open('tel:' + supportPhone)}
                            className='poppinsLight' style={{
                                padding: '5px',
                                display: 'flex',
                                // backgroundColor:'red',
                                borderRadius: 8,
                                color: '#0F0F0F',
                                cursor: 'pointer',
                            }}>
                            <CallIcon style={{ marginRight: '4px', color: '#F5BC01' }} />
                            {supportPhone}

                        </span>
                        <span
                            onClick={() => {
                                window.open('mailto:' + supportEmail)
                            }}
                            className='poppinsLight' style={{
                                padding: '5px',
                                display: 'flex',
                                // backgroundColor:'red',
                                borderRadius: 8,
                                color: '#0F0F0F',
                                cursor: 'pointer',
                            }}>
                            <EmailOutlinedIcon style={{ marginRight: '4px', color: '#F5BC01' }} />
                            {supportEmail}


                        </span>
                        <span className='poppinsLight' style={{
                            padding: '5px',
                            display: 'flex',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: '#0F0F0F',
                            cursor: 'pointer',
                        }}>
                            <LocationOnIcon style={{ marginRight: '4px', color: '#F5BC01' }} />
                            {supportAddress}



                        </span>
                    </div>
                    <Stack
                        sx={{
                            width: '100%',
                            marginTop: '5%'
                        }}

                        direction={'row'}
                    >
                        <a
                            target='_blank'
                            href={fackbook_url}>
                            <img src={fbIcon} alt="fb"
                                style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
                        </a>
                        <a
                            target='_blank'
                            href={instagram_url}>
                            <img
                                src={instaIcon}
                                style={{ width: '50px', height: 'auto', marginRight: '15px' }}
                            />
                        </a>
                        <a
                            target='_blank'
                            href={twitter_url}>
                            <img src={twitterIcon}
                                style={{ width: '50px', height: 'auto', marginRight: '15px' }}
                            />
                        </a>
                        <a
                            target='_blank'
                            href={linkedin_url}>
                            <img src={linkedinIcon}
                                style={{ width: '50px', height: 'auto', marginRight: '15px' }}
                            />
                        </a>
                    </Stack>

                </Grid> */}
            </Grid>


        </>
    );
}

export default Footer;